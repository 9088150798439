body {
  background-image: url("./images/background.jpg");
}

.Header {
  color: burlywood;
  margin-top: 100px;
  padding-bottom: 50px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  font-size: 26px;
  border-bottom: 1px solid white;
}

.content {
  display: flex;
  margin-top: 30px;
  justify-content: space-around;
  align-items: center;
}

.container {
  color: lightskyblue;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  flex-direction: column;
}

.button {
  width: 100%;
  margin: 13px;
}

.button_cont {
  width: 120%;
  display: flex;
  justify-content: space-between;
}

.prod_button {
  background-color: red;
  border: 2px solid #422800;
  border-radius: 30px;
  box-shadow: #422800 4px 4px 0 0;
  color: #422800;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  padding: 0 18px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.stag {
  background-color: blue;
  color: #fff;
}
.stag:hover {
  color: #422800;
}

.prod_button:hover {
  background-color: #fff;
}

.prod_button:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .prod_button {
    min-width: 120px;
    padding: 0 25px;
  }
}

@page {
  size: 1980px;
}


